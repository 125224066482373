import { Col, Container, Row } from "react-bootstrap";
import PufferLogo from "../../assets/Puffer-logo.svg";
import AppleAppStoreLogo from "../../assets/appstore.png";
import GooglePlayStoreLogo from "../../assets/googleplay.png";

const ThankYou = () => {
  return (
    <Container className="w-sm-100 w-md-50 w-lg-50">
      <Row className="mt-3 p-4 text-center">
        <Col>
          <img
            className="img-fluid"
            src={PufferLogo}
            alt="Puffer app"
            width={500}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>Super! Je account is geactiveerd</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            Je kan nu inloggen in de app met het zojuist door jou aangemaakte
            wachtwoord!
          </p>

          <h4>Heb je de app nog niet gedownload?</h4>
          <p>
            Klik afhankelijk van het type telefoon dat jullie gebruiken
            hieronder op de link om de Puffer-app te installeren.
          </p>

          <p className="mb-1">
            Android (Samsung, Huawei, LG, Sony, One plus etc) link naar de
            Google Playstore:
          </p>

          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=nl.mst.puffer"
          >
            <img width={200} src={GooglePlayStoreLogo} />
          </a>

          <p className="mb-1 mt-2">
            Apple (Iphones) link naar de Apple app store:
          </p>

          <a
            target="_blank"
            href="https://apps.apple.com/app/puffer/id1643099651"
          >
            <img width={200} src={AppleAppStoreLogo} />
          </a>

          <p className="mt-2">
            Als jullie dat hebben gedaan kunnen jullie inloggen in de app met
            jullie gebruikersnaam (dit e-mail adres: ) en het zojuist gemaakte
            wachtwoord. In de app staat bij de informatiepagina meer uitleg over
            de eHealthzorg en de functies van de app. Mochten jullie specifieke
            vragen hebben stel ze gerust via de chat.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default ThankYou;
