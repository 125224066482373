import { Col, Container, Row } from "react-bootstrap";
import PufferLogo from "../assets/Puffer-logo.svg";

const PrivacyStatement = () => {
  return (
    <Container className="w-sm-100 w-md-50 w-lg-50">
      <Row className="mt-5 p-4">
        <Col className="text-center">
          <img
            className="img-fluid"
            src={PufferLogo}
            alt="Puffer app"
            width={500}
          />
        </Col>
      </Row>
      <div>
        <h1>Privacy verklaring Puffer App</h1>
        <span>Laatst geüpdatet 15 augustus 2022</span>
        <p className="mt-3">
          Deze privacyverklaring voor de Puffer App van het Medisch Spectrum
          Twente (“Bedrijf”, “wij”, “ons”) beschrijft hoe en waarom uw data
          gebruikt wordt, met welk doel en de grondslag van de verwerking van uw
          persoonsgegevens.{" "}
        </p>
        <p>
          <strong>1. Welke informatie verzamelen wij?</strong>
        </p>
        <p>
          <strong>Persoonsgegevens die u aan ons verstrekt</strong>
        </p>
        <ul>
          <li>Voornaam</li>
          <li>Achternaam</li>
          <li>Geboortedatum</li>
          <li>Lengte (in cm)</li>
          <li>Doel eHealth patiënt (doel gebruik app)</li>
          <li>Contactpersonen</li>
          <li>Authenticatie gegevens</li>
        </ul>

        <p>
          <strong>Gevoelige gegevens</strong>
        </p>
        <ul>
          <li>Gezondheid gerelateerde gegevens</li>
          <li>Afkomst</li>
          <li>Data longfunctiemetingen</li>
        </ul>

        <p>
          <strong>Andere gegevens</strong>
        </p>
        <ul>
          <li>Video's</li>
          <li>Foto's</li>
          <li>Chat berichten</li>
        </ul>

        <p>
          <strong>Applicatie data</strong>
          <p>
            Bij het gebruik van onze app, kunnen we ook de volgende informatie
            verzamelen als u ervoor kiest om ons toestemming te geven voor:
          </p>
          <ul>
            <li>
              Push notificaties. We kunnen u om toestemming vragen om
              pushmeldingen te sturen met betrekking tot bepaalde functies
              binnen de applicatie. Als u zich wilt afmelden voor het ontvangen
              van dit soort meldingen, kunt u deze uitschakelen in de
              instellingen van uw apparaat.
            </li>
          </ul>
        </p>

        <p>
          <strong>2. Hoe en waarom verwerken wij uw gegevens?</strong>
        </p>
        <ul>
          <li>
            <strong>
              Om het maken en authentiseren van accounts mogelijk te maken.
            </strong>{" "}
            Wij verwerken uw gegevens zodat u uw account kunt aanmaken en erop
            kunt inloggen.
          </li>
          <li>
            <strong>
              Om het mogelijk maken om de gevraagde dienst te leveren aan de
              gebruiker.
            </strong>{" "}
            Wij verwerken uw gegevens zodat u uw account kunt aanmaken en erop
            kunt inloggen.
          </li>
        </ul>

        <p>
          <strong>
            3. Welke verwerkingsgrondslagen hebben betrekking op de verwerking
            van uw gegevens?
          </strong>
        </p>

        <p>We verwerken uw gegevens om de volgende redenen:</p>
        <ul>
          <li>
            <strong>Toestemming. </strong>Wij verwerken (mogelijk) uw gegevens
            indien u ons van toestemming heeft voorzien om jouw persoonsgegevens
            te mogen verwerken.
          </li>
          <li>
            <strong>Uitvoeren van een overeenkomst. </strong>Wij verwerken
            (mogelijk) uw persoonsgegeven omdat wij deze nodig hebben om te
            voldoen aan onze contractuele verplichtingen richting u.
          </li>
          <li>
            <strong>Wettelijke verplichtingen. </strong>Wij verwerken (mogelijk)
            uw persoonsgegevens wanneer wij denken dat dit nodig is om aan onze
            wettelijke verplichtingen te voldoen, zoals om samen te werken met
            wetshandhavingsinstantie of regelgevende instantie, om onze
            wettelijke rechten uit te oefenen of te verdedigen.
          </li>
        </ul>

        <p>
          <strong>4. Hoe lang bewaren wij uw gegevens?</strong>
        </p>

        <p>
          Wanneer u gedurende een periode van 12 maanden de app niet gebruikt
          (dat wil zeggen dat u gedurende 12 maanden niet op uw account inlogt),
          dan worden de persoonsgegevens in de database automatisch
          geanonimiseerd.
        </p>

        <p>
          <strong>5. Hoe houden we uw gegevens veilig?</strong>
        </p>
        <p>
          Wij hebben technische en organisatorische beveiligingsmaatregelen
          geïmplementeerd om de veiligheid van de persoonsgegevens die wij
          verwerken te beschermen. De persoonsgegeven worden opgeslagen op
          beveiligde servers, overgedragen via een verbinding beveilig met SSL.
          Daarnaast nemen wij aanvullende informatiebeveiligingsmaatregelen,
          waaronder toegangscontroles en procedures voor het verzamelen, opslaan
          en verwerken van deze gegevens.
        </p>
        <p>
          <strong>6. Uw rechten</strong>
        </p>
        <p>
          Ten aanzien van de verwerking van uw persoonsgegevens heeft u
          verschillende rechten, waaronder het recht tot inzage, correctie,
          verwijdering en overdraagbaarheid van uw persoonsgegevens, alsmede tot
          beperking van de verwerking van uw persoonsgegevens. Ten slotte kunt u
          ook bezwaar indienen tegen de verwerking van uw persoonsgegevens.{" "}
          <br></br>
          <br></br>
          Uw rechten kunnen worden beperkt als bijvoorbeeld het inwilligen van
          uw verzoek ertoe zou leiden dat inbreuk wordt gemaakt op
          (privacy)rechten van derden, of wanneer u verwijdering verzoekt van
          gegevens die wij en/of onze dienstverleners dienen te bewaren op grond
          van een wettelijke verplichting, een gerechtvaardigd belang of een
          andere juridische grondslag.<br></br>
          <br></br>
          Mocht u een verzoek willen indienen, of vragen hebben over de manier
          waarop uw persoonsgegevens worden verwerkt, dan kunt u contact opnemen
          met de data protection officer via: m.vanderkamp@mst.nl.
        </p>
        <p>
          <strong>7. Wijzigen van deze privacyverklaring</strong>
        </p>
        <p>
          Deze privacyverklaring kan worden aangepast. U wordt op de hoogte
          gebracht van de gewijzigde privacyverklaring bij het opstarten van de
          app. Tevens kan deze privacyverklaring ten alle tijden opgevraagd
          worden via de app.
        </p>

        <p>
          <strong>
            8. Hoe kan u contact opnemen met ons over deze privacyverklaring?
          </strong>
        </p>
        <p>
          Bij vragen of opmerkingen over deze verklaring kunt u ons per email
          benaderen via:{" "}
          <a href="mailto:m.vanderkamp@mst.nl">m.vanderkamp@mst.nl</a>.<br></br>
          <br></br>
          Medisch Spectrum Twente
          <br></br>
          Koningstraat 1<br></br> 7512 KZ Enschede
        </p>
      </div>
    </Container>
  );
};

export default PrivacyStatement;
