import { ActivateAccountInput } from "../types";
import { ApiClient } from "./client";

let client = new ApiClient();

export default {
  validateActivationCode(code: string) {
    return client.put("/v1/Account/ValidateActivationCode", { code: code });
  },
  validatePasswordResetCode(code: string) {
    return client.post("/v1/Account/ValidatePasswordResetCode", { code: code });
  },
  activateAccount(input: ActivateAccountInput) {
    return client.post("/v1/Account", input);
  },
  resetPassword(input: ActivateAccountInput) {
    return client.post("/v1/Account/ChangePassword", input);
  },
};
