import { useQuery } from "../../utility/hooks/useQuery";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import accountApi from "../../api/account";
import { Col, Container, Row } from "react-bootstrap";
import PufferLogo from "../../assets/Puffer-logo.svg";
import Search from "../../assets/search.svg";
import CreatePassword from "./CreatePassword";
import InvalidCode from "./InvalidCode";

const ActivateAccount = () => {
  const navigate = useNavigate();

  const query = useQuery();
  const activationCode = query.get("activationCode");
  const [validating, setValidating] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const validateCode = async () => {
    if (!activationCode) {
      navigate("/account/activate/invalidcode");
      return;
    }
    await accountApi
      .validateActivationCode(activationCode || "")
      .then((result) => {
        const isValid = result.data.data;
        if (!isValid) {
          setIsValid(false);
        } else {
          setIsValid(true);
        }
        setValidating(false);
      });
  };

  useEffect(() => {
    setValidating(true);
    validateCode();
  }, []);

  if (validating)
    return (
      <Container className="w-sm-100 w-md-50 w-lg-50">
        <Row className="mt-5 p-4">
          <Col className="text-center">
            <img
              className="img-fluid"
              src={PufferLogo}
              alt="Puffer app"
              width={500}
            />
          </Col>
        </Row>
        <Row className="mt-5 p-4">
          <Col className="text-center">
            <img
              className="img-fluid"
              src={Search}
              alt="Account gegevens worden opgezocht"
              width={100}
            />
          </Col>
        </Row>

        <Row>
          <Col className="text-center">
            <p>
              <strong>Even wachten, we zijn op zoek naar je gegevens.</strong>
            </p>
          </Col>
        </Row>
      </Container>
    );

  if (!isValid && !validating) return <InvalidCode />;
  return <CreatePassword activationCode={activationCode!} />;
};

export default ActivateAccount;
