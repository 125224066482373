import { Col, Container, Row } from "react-bootstrap";

import PufferLogo from "../../assets/Puffer-logo.svg";

const InvalidCode = () => {
  return (
    <Container className="w-sm-100 w-md-50 w-lg-50">
      <Row className="mt-5 p-4">
        <Col className="text-center">
          <img
            className="img-fluid"
            src={PufferLogo}
            alt="Puffer app"
            width={500}
          />
        </Col>
      </Row>

      <Row>
        <p>
          Oeps, deze link werkt niet meer. Dit kan betekenen dat je je account
          al geactiveerd hebt. Ga dan naar de app en log in.
        </p>
        <p>Werkt dat niet? Vraag dan om hulp aan de zorgverlener!</p>

        <p>Je kan deze pagina nu sluiten.</p>
      </Row>
    </Container>
  );
};

export default InvalidCode;
