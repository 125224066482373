import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ActivateAccount from "./pages/account/ActivateAccount";
import InvalidCode from "./pages/account/InvalidCode";
import PrivacyStatement from "./pages/PrivacyStatement";
import ThankYouReset from "./pages/account/ThankYouReset";
import ResetAccountPassword from "./pages/account/ResetAccountPassword";
import ThankYou from "./pages/account/ThankYou";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<App />} />
      </Route>
      <Route path="/account/activate" element={<ActivateAccount />} />
      <Route path="/account/activate/thankyou" element={<ThankYou />} />
      <Route
        path="/account/reset-password"
        element={<ResetAccountPassword />}
      />
      <Route path="/account/activate/invalidcode" element={<InvalidCode />} />
      <Route path="/privacy-statement" element={<PrivacyStatement />} />
      <Route path="/privacy-statement" element={<PrivacyStatement />} />
      <Route
        path="/account/resetpassword/thankyou"
        element={<ThankYouReset />}
      />
    </Routes>
  </BrowserRouter>
);

reportWebVitals();
