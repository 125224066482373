import "./App.css";
import { nl } from "yup-locales";
import { setLocale } from "yup";

setLocale(nl);

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Puffer app</h1>
        <p>Gebruik de link in je mail om je account te activeren.</p>
      </header>
    </div>
  );
};

export default App;
