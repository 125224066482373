import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const regularSweetAlert = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary",
  },
  buttonsStyling: false,
});

const warningSweetAlert = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary",
    cancelButton: "btn btn-danger me-50",
  },
  buttonsStyling: false,
});

const Message = (title: any, message: any, type: any, customData?: any) => {
  if (type === "warning") {
    return warningSweetAlert.fire({
      heightAuto: false,
      title,
      html: message,
      icon: type,
      ...customData,
      showCancelButton: true,
      confirmButtonText: "Bevestigen",
      cancelButtonText: "Annuleren",
      reverseButtons: true,
    });
  } else {
    return regularSweetAlert.fire({
      heightAuto: false,
      title,
      html: message,
      icon: type,
      ...customData,
    });
  }
};

export default Message;
