import { Button, Col, Container, Form, Row } from "react-bootstrap";
import PufferLogo from "../../assets/Puffer-logo.svg";
import * as Yup from "yup";
import { PasswordRequirements } from "../../utility/constants/common";
import { ActivateAccountData, ActivateAccountInput } from "../../types";
import { useState } from "react";
import accountApi from "../../api/account";
import { Field, Formik } from "formik";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required()
    .matches(PasswordRequirements.expression, PasswordRequirements.label)
    .label("Wachtwoord"),
  passwordConfirmation: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "De wachtwoorden moeten overeenkomen")
    .label("Herhaal wachtwoord"),
});

interface Props {
  activationCode: string;
}

const ResetPassword = ({ activationCode }: Props) => {
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (data: ActivateAccountData) => {
    setProcessing(true);

    data.code = activationCode!;

    await accountApi
      .resetPassword(data)
      .then((result) => {
        navigate("/account/resetpassword/thankyou");
      })
      .catch(() => {
        setProcessing(false);
      });
  };

  const initialValues: ActivateAccountInput = {
    password: "",
    code: "",
    passwordConfirmation: "",
  };

  return (
    <>
      <Container className="w-sm-100 w-md-50 w-lg-50">
        <Row className="mt-5 p-4">
          <Col className="text-center">
            <img
              className="img-fluid"
              src={PufferLogo}
              alt="Puffer app"
              width={500}
            />
          </Col>
        </Row>

        <Row>
          <p>Herstel je Puffer app wachtwoord!</p>
          <p>
            Je bent op deze pagina gekomen omdat je een nieuw wachtwoord wil
            instellen voor je Puffer account. Voer hieronder een nieuw
            wachtwoord in! Daarna kun je met je email adres en je nieuwe
            wachtwoord inloggen op de app.
          </p>
        </Row>

        <Row>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, errors, touched }) => (
              <Form className="auth-login-form mt-2" onSubmit={handleSubmit}>
                <>
                  <div className="mb-1">
                    <label className="form-label" htmlFor="password">
                      Wachtwoord
                    </label>
                    <Field
                      className="form-control"
                      id="password"
                      name="password"
                      type="password"
                    />

                    {errors.password && touched.password ? (
                      <div className="text-danger">{errors.password}</div>
                    ) : null}
                  </div>

                  <div className="mb-1">
                    <label
                      className="form-label"
                      htmlFor="passwordConfirmation"
                    >
                      Herhaal wachtwoord
                    </label>
                    <Field
                      className="form-control"
                      id="passwordConfirmation"
                      name="passwordConfirmation"
                      type="password"
                    />
                    {errors.passwordConfirmation &&
                    touched.passwordConfirmation ? (
                      <div className="text-danger">
                        {errors.passwordConfirmation}
                      </div>
                    ) : null}
                  </div>

                  <div className="mt-4">
                    {processing ? (
                      <p>Je wachtwoord wordt gewijzigd....</p>
                    ) : (
                      <Button className="m-font-white" type="submit">
                        Wachtwoord bijwerken
                      </Button>
                    )}
                  </div>
                </>
              </Form>
            )}
          </Formik>
        </Row>
      </Container>
    </>
  );
};

export default ResetPassword;
